import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DATE_FORMAT_INPUT } from "./../../config/constant";


const  Calendra = React.forwardRef((props,ref)=> {
    // const [value, onChange] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());  
     return (
        <div className="datepicker">
            <DatePicker
                dateFormat={DATE_FORMAT_INPUT}
                {...props}
                placeholderText ={props.placeholderText || "--/--/--"}
            />
        </div>
    );
})
export default Calendra