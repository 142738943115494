import React, { Component } from 'react';
import Pagination from "./pagination"
import Loader from '../elements/Loader';
import { numberFormatWithDots } from '../common/Helper';
import { DATE_FORMAT_DMY} from "../../config/constant";
import dateFormat from "dateformat";
import { withTranslation } from 'react-i18next';

class SalaryReportTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            salaryReportList: [],
            pagination : {},
            loading: false,
        };
    }

    static getDerivedStateFromProps (props, state) {
        return {
            filterData : props.filterData ,
            salaryReportList : props.list || [],
            pagination : props.page,
            loading: props.isLoading || false,
        }
    }

    getPaginatedData = (value) => {
        return this.props.getSalaryReportList(value)
    }

    render() {

        let { salaryReportList,filterData ,pagination,loading } = this.state;
        return (

<div className='salary-data-report-table-outer finance-report-outer'>
    <div className='table-scrollable-data'>
        <table>
            <thead>
                <tr>
                    <th>{this.props.t("SALARY_REPORT.NPWP")}</th>
                    <th>{this.props.t("SALARY_REPORT.JOIN_DATE")}</th>
                    <th>{this.props.t("SALARY_REPORT.BASIC_SALARY")}</th>
                    <th>{this.props.t("SALARY_REPORT.TRANSPORTATION_ALLOWANCE")}</th>
                    <th>{this.props.t("SALARY_REPORT.COMMUNICATION_ALLOWANCE")}</th>
                    <th>{this.props.t("SALARY_REPORT.TOTAL_INCOME")}</th>
                    <th>{this.props.t("SALARY_REPORT.BRO_COMMUNICATION_ALLOWANCE")}</th>
                    <th>{this.props.t("SALARY_REPORT.PROFESSIONAL_ALLOWANCE")}</th>
                    <th>{this.props.t("SALARY_REPORT.INCENTIVE")}</th>
                    <th>{this.props.t("SALARY_REPORT.BONUS")}</th>
                    <th>{this.props.t("SALARY_REPORT.SALARY_DEDUCTION")}</th>
                    <th>{this.props.t("SALARY_REPORT.GROSS_INCOME")}</th>
                    <th>{this.props.t("SALARY_REPORT.SALARY_PERIOD")}</th>
                    <th>{this.props.t("SALARY_REPORT.TAKE_HOME_PAY")}</th>
                 
                </tr>
            </thead>
            <tbody>
            {salaryReportList.length !== 0 ? salaryReportList.map((obj, index) => (
                <tr key={index}>
                    <td>{obj.npwp_number || ""}</td>
                    <td>{obj.joining_date ? dateFormat(obj.joining_date,DATE_FORMAT_DMY):''}</td>
                    <td>{numberFormatWithDots(obj.basic_salary) || ""}</td>
                    <td>{numberFormatWithDots(obj.transportation_allowance) || ""}</td>
                    <td>{numberFormatWithDots(obj.communication_allowance) || ""}</td>
                    <td>{numberFormatWithDots(+obj.basic_salary + +obj.communication_allowance + +obj.transportation_allowance) || ""}</td>
                    <td>{numberFormatWithDots(obj.bro_communication_allowance) || ""}</td>
                    <td>{numberFormatWithDots(obj.professional_allowance) || ""}</td>
                    <td>{numberFormatWithDots(obj.incentive) || ""}</td>
                    <td>{numberFormatWithDots(obj.bonus) || ""}</td>
                    <td>{numberFormatWithDots(obj.salary_deduction) || ""}</td> 
                    <td>{numberFormatWithDots(obj.gross_income) || ""}</td>  
                    <td>{obj.salary_period || ""}</td> 
                    <td>{numberFormatWithDots(obj.take_home_pay )|| ""}</td>                 
                </tr>
               )) :  <tr><td colSpan={7}> {!loading ?  'No record found':''}</td></tr>}
            </tbody>
        </table>
        {loading ? <Loader isLoading={loading} />:null}
    </div>
    <Pagination 
    filterData = {filterData}
    list = {salaryReportList}
    page = {pagination}
    getPaginatedData = {this.getPaginatedData}
    />
</div>

        )
    }
}
export default withTranslation("language")(SalaryReportTable);