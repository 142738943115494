import secureStorage from "./encrypt";
export const API_URL = {
	GETWAY_API: process.env.REACT_APP_GETWAY_API,
	COMMON_GATEWAY_API: process.env.REACT_APP_GETWAY_API,
};

export const SETTING = {
	CRYPTO_KEY: "U*oDwrIU!B#QX37%#24",
	RESEND_OTP_TIME_IN_SECOND: 30,
};
export const DATE_FORMAT = "dd mmm yyyy, HH:MM";
export const DATE_FORMAT_DMY = "dd mmm yyyy";
export const DATE_FORMAT_INPUT = "yyyy-MM-dd";
export const DATE_FORMAT_YMD = "yyyy-mm-dd";
export const DATE_FORMAT_MDY = "mmm dd, yyyy";
export const DATE_FORMAT_INPUT_TIME = "yyyy-MM-dd h:mm aa";

export const DATE_FORMAT_FILTER = "yyyy-mm-dd";
export const IS_NUMBER_VALIDATION = /^[0-9]*$/;


export const displayAmount = (amount) => {
	let amount2display= `IDR ${amount}`;
	return amount2display;
};



// manage menu Permission for Roles 
export const hasAccessPermission = (modulename,page='view') => {
	const userAccess = secureStorage.getItem("userAccess");
	let Permission = userAccess&& userAccess[modulename] && userAccess[modulename][page]=="1"?true:false;
	return Permission;
};
export const maxDATE = (from_date,d=0)=>{
	var date = new Date(from_date);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + d);
	return lastDay
}

export const DATE_FORMAT_MY = 'MM/yyyy';

export const AMOUNT_FORMAT_TYPE = [
	{ value: 'en', label: 'Indian Accounting Standard System' },
	{ value: 'id', label: 'Indonesian Accounting Standard System' }
];

export const INCENTIVE_TYPE = [
    { value: 1, label: 'Allowance' },
    { value: 2, label: 'Allowance + Incentives' },
    { value: 3, label: 'Incentives' }
];
export const SFA_USER_ROLE = [
    { value: 'agent', label: 'Agent' },
    { value: 'bro', label: 'BRO' },
    { value: 'aro', label: 'ARO' },
    { value: 'arco', label: 'ARCO' },
    { value: 'bm', label: 'BM' }
];

export const BANK_LIST_OBJECTS = {
	"BCA":{acc_number : '0845253451',outbound_acc_number : '0844545556',label:'BCA'},
	"BCA2":{acc_number : '0842335521',outbound_acc_number : '0842335521',label:'BCA'},
	"Mandiri":{acc_number : '1170010080802',outbound_acc_number : '1170010080802',label:'Mandiri'},
	"CIMB-Niaga":{acc_number : '800157674000',outbound_acc_number : '800157674000',label:'CIMB-Niaga'},
	"Danamon":{acc_number : '3622268310',outbound_acc_number : '3622268310',label:'Danamon'},
};
export const FAM_ALLOWED_DOC_CATEGORY = [13, 14, 60, 61, 62, 17, 64, 18, 15, 51, 16, 52, 19, 53, 54, 58];
export const UNWIND_ARRAY = function(arr,by) {
	var results = [];
	arr.forEach(function(subArray) {
		let obj = {...subArray};
		delete obj[by];
		if(subArray[by].length){
			subArray[by].forEach(function(item) {
				item = {[by]:item,...obj};
				results.push(item);
			});
		}
		else{
			subArray[by]={};
			results.push(subArray);
		}
	});
	return results;
};
export const BANK_LIST = [
	{ value: "BCA", label: "BCA",account_number : '0845253451',outbound_account_number : '0844545556',is_show_outbound : true },
	{ value: "BCA2", label: "BCA",account_number : '0842335521',outbound_account_number : '0842335521',is_show_outbound : false },
	{ value: "Mandiri", label: "Mandiri",account_number : '1170010080802',outbound_account_number : '1170010080802',is_show_outbound : true },
	{ value: "CIMB-Niaga", label: "CIMB-Niaga",account_number : '800157674000',outbound_account_number : '800157674000',is_show_outbound : false },
	{ value: "Danamon", label: "Danamon",account_number : '3622268310',outbound_account_number : '3622268310',is_show_outbound : false },
];

