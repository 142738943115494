import React, { Component } from 'react';
class Pagination extends Component {
    constructor(props) {
        super(props);
        console.log(props)

        this.state = {
            pagination : {},
        };
    }
    static getDerivedStateFromProps (props, state) {
        return {
            filterData : props.filterData,
            pagination : props.page,
            list : props.list
        }
    }
    setPage = (e) => {
        let filterData = this.state.filterData
        console.log(e)
        let pageNum = e.target.text
        if(filterData !== undefined){
            filterData.page = pageNum
        this.props.getPaginatedData(filterData)
        } 
        else
        this.props.getPaginatedData({page : pageNum})
    }

    onNext = () => {
        let filterData = this.state.filterData
        let curr_page = (this.state.pagination.cur_page+1)
        if(filterData !== undefined){
            filterData.page = curr_page
        this.props.getPaginatedData(filterData)
        } 
        else
        this.props.getPaginatedData({page : curr_page})
      };
    
    onPrevious = () => {
        let filterData = this.state.filterData
        let page = this.state.pagination.cur_page-1
        if(filterData !== undefined){
            filterData.page = page
        this.props.getPaginatedData(filterData)
        } 
        else
        this.props.getPaginatedData({page : page})
      };

    render() {
        let { pagination ,list} = this.state;
        const pageNumbers = []
        if (pagination){
        for (let i = 1; i <= Math.ceil(pagination.total / pagination.limit); i++) {
          pageNumbers.push(i);
        }
        }
    
 
        return (
            <div className="pagination-bx">
                <ul>
                <li className="prev">
                        <a onClick={pagination ? (pagination.prev_page ? () => this.onPrevious() : null) : null }>
                            <i className="ic-keyboard_arrow_left"></i>
                        </a>
                    </li>
                   
                    {pageNumbers.map((pageNum, index) => (
                         <li key={index} className = {pageNum == pagination.cur_page? "active" : "" }>
                         <a onClick={(e) => this.setPage(e)}>{pageNum}</a>
                         </li>
                     ))}
                   
                    <li className="next">
                    <a onClick={pagination ? (pagination.next_page ? () => this.onNext() : null) : null}>
                            <i className="ic-keyboard_arrow_right"></i>
                        </a>
                    </li>
                </ul>
            </div>

        )
    }
}
export default Pagination;