import React, { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import PaymentService from "../../service/paymentService";
import secureStorage from "./../../config/encrypt";
import { useDispatch } from "react-redux";
import { updateCurrency, userDetail } from "../../store/allAction";
import {CURRENCY_INFO,hasAccessPermission} from "../../config/constant";

function EmailUser() {
	const dispatch = useDispatch();
	const btnRefs = useRef()
	const [inputField, setInputField] = useState({
		email: "",
		password: "",
	});
	const [errField, setErrField] = useState({
		emailErr: "",
		passwordErr: "",
	});
	const history = useNavigate();
	const validForm = () => {
		let formIsValid = true;
		setErrField({
			emailErr: "",
			passwordErr: "",
		});
		if (inputField.email == "") {
			formIsValid = false;
			setErrField((prevState) => ({
				...prevState,
				emailErr: "Please Enter Email",
			}));
		}
		if (inputField.password == "") {
			formIsValid = false;
			setErrField((prevState) => ({
				...prevState,
				passwordErr: "Please Enter password",
			}));
		}
		return formIsValid;
	};
	const inputHandler = (e) => {
		btnRefs.current.removeAttribute("disabled");
		if (e.target.name === 'email') {
			setErrField((prevState) => ({
				...prevState,
				emailErr: "",
			}));
		}
		if (e.target.name === 'password') {
			setErrField((prevState) => ({
				...prevState,
				passwordErr: "",
			}));
		}
		setInputField({ ...inputField, [e.target.name]: e.target.value });
	};
	const focusEmail = useRef(); 
	const focusPwd = useRef(); 
	useEffect(() => { 
		const loggedInUser = secureStorage.getItem("authUserInfo");
		if(loggedInUser && loggedInUser.user_data){ 
			let url2redirct = (hasAccessPermission('salary-report')) ? `/salary-report` : '/incentive/pending/incentive_payment';
			history(url2redirct)
		}else{
			if (focusEmail.current){
				setTimeout(()=>{
					focusEmail.current.focus(); 
				},500)
				setTimeout(()=>{
					focusPwd.current.focus(); 
				},400) 
			}
		}  
	}, [focusEmail]);

	const submitButton = async () => {
		btnRefs.current.setAttribute("disabled", "disabled"); 
		if (validForm()) {
			let response = await PaymentService.userLogin(inputField); 
			if (response && response.status == 200) {
				let {permissions}=response.data.data;
				let userInfo = response.data.data;
				secureStorage.setItem("authUserInfo", userInfo);
				secureStorage.setItem("userAccess", permissions);
				dispatch(userDetail(userInfo.user_data));
				toast.success(`Login Successfully`);
				//let authUser = userInfo.user_data;
				let url2redirct = (hasAccessPermission('salary-report')) ? `/salary-report` : '/incentive/pending/incentive_payment'
				history(url2redirct)
				//setTimeout(() => history.push(url2redirct), 1000); 
			}else{
				btnRefs.current.removeAttribute("disabled");
			}
		}
	};
	const handleKeyPress = (e) => {
		if (e.charCode == 13) submitButton();
		return true
	}
	return (
		<div className="login-form">
			<form autoComplete="on">
				<fieldset className="">
					<div
						className={
							"material formfield " +
							(errField.emailErr.length > 0 ? "error" : "")
						}
					>
						<input
							type="text"
							placeholder=" "
							name="email"
							value={inputField.email}
							className="form-input"
							onChange={inputHandler}
							ref={focusEmail}
						/>
						<i className="ic-email1 icn-login"></i>
						<label
							data-label="Email"
							className="form-label"
						></label>
						{errField.emailErr.length > 0 && (
							<span className="erroe-txt">
								{errField.emailErr}
							</span>
						)}
					</div>
				</fieldset>
				<fieldset className="">
					<div
						className={
							"material " +
							(errField.passwordErr.length > 0 ? "error" : "")
						}
					>
						<input
							type="password"
							placeholder=" "
							name="password"
							value={inputField.password}
							className="form-input"
							onChange={inputHandler}
							onKeyPress={handleKeyPress}
							ref={focusPwd}
						/>
						<i className="ic-password icn-login"></i>
						<label
							data-label="Password"
							className="form-label"
						></label>
						{errField.passwordErr.length > 0 && (
							<span className="erroe-txt">
								{errField.passwordErr}
							</span>
						)}
					</div>
				</fieldset>
				<button
					type="button"
					className="btn-primary"
					onClick={submitButton}
					ref={btnRefs}
				>
					Login
				</button>
			</form>
		</div>
	);
}

export default EmailUser;
