import React, { useState, useEffect } from 'react';
import { BANK_LIST, DATE_FORMAT_INPUT_TIME, hasAccessPermission, maxDATE } from "../../config/constant"
import { useTranslation } from "react-i18next";
import Select, { components } from 'react-select';
import DatePicker from "../elements/DatePicker";
import secureStorage from "../../config/encrypt";
import { PaymentService } from '../../service';
import { toast } from 'react-toastify';
import { numberFormatWithDots } from '../common/Helper';
const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, child =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
};
const UpdatePayment = ({ paymentData, toggleUpdatePayment,payment_source }) => {
    const { t } = useTranslation("language");
    const [formData, setFormData] = useState({})
    const [errors, setError] = useState({})
    const [isPaymentUpdating, setIsPaymentUpdating] = useState(false);
    const [maxTime, setMaxTime] = useState(new Date().setHours(23, 30, 0));
    const loggedInUser = secureStorage.getItem("authUserInfo");
    useEffect(() => {
        setFormData((prev) => ({
            ...prev,
            payment_amount: paymentData.amount_to_be_paid,
            utr_number: paymentData.utr_number || 0,
            payment_date: new Date(),
            bank_name: "",
            user_incentive_id: paymentData.incentive_id,
            user_id: (loggedInUser.user_data && loggedInUser.user_data.user_id) || 0,
            payment_source:payment_source || 'finance'
        }));
        setIsPaymentUpdating(false);
        setError({});
    }, [paymentData]);

    useEffect(() => {
        if (formData.payment_date) {
          let dateTime = new Date(formData.payment_date);
          let selectedDateTime = new Date(dateTime.getTime());
          let currhour = new Date().getHours();
          let currmin = new Date().getMinutes();
          if (selectedDateTime.setHours(0,0,0,0) === new Date().setHours(0,0,0,0)) {
            setMaxTime(new Date().setHours(currhour, currmin, 0));
          } else {
            setMaxTime(new Date().setHours(23,30,0));
          }
        }  
    }, [formData.payment_date]);

    const changeHandler = (name, value) => {
        setFormData((prev) => ({ ...prev, [name]: value }));
        setError((prev) => ({ ...prev, [name]: '' }))
    }
    const validatePayment = () => {
        if (!formData.payment_amount) errors.payment_amount = 'Error'; else errors.payment_amount = '';
        if (!formData.bank_name) errors.bank_name = 'Error'; else errors.bank_name = '';
        if (!formData.payment_date) errors.payment_date = 'Error'; else errors.payment_date = '';
        if (!formData.utr_number) errors.utr_number = 'Error'; else errors.utr_number = '';
        if (errors.bank_name || errors.payment_amount || errors.payment_date || errors.utr_number) {
            setError({ ...errors });
            return false;
        }
        return true;
    }
    const handleSubmit = async () => {
        if (validatePayment()) {
            setIsPaymentUpdating(true);
            console.log('formData==================',formData);
            await PaymentService.savePaymentDetails(formData)
                .then(response => {
                    if (response.status === 200 && response.data.status === 200) {
                        toast.success(response.data.message);
                        toggleUpdatePayment(false, {});
                    } else {
                        setIsPaymentUpdating(false);
                    }
                })
        }
    }
    let modified_bank_list = BANK_LIST.filter(f=>f.is_show_outbound).map(e=>{
        e.display_bank_name = e.label+' - '+e.outbound_account_number;
        return e;
    })
    return (
        <div className="modal-body" >
            <div className="row">
                <fieldset className={errors.payment_amount ? 'form-filed col-md-6 required-msg' : 'form-filed col-md-6'}>
                    <div className="material">
                        <input
                            id="payment_amount"
                            type="text"
                            placeholder=" "
                            name="payment_amount"
                            className="form-input"
                            value={numberFormatWithDots(formData.payment_amount || "")}
                            onChange={(v) => changeHandler('payment_amount', v.target.value)}
                            disabled
                        />
                        <label data-label={t("INCENTIVE_PAYMENT.UPDATE_PAYMENT.AMOUNT_PAID")} className="form-label"></label>
                    </div>
                </fieldset>
                <fieldset className={errors.bank_name ? 'single-select col-md-6 required-msg' : 'single-select col-md-6'}>
                    <div className="material">
                        <Select components={{ ValueContainer: CustomValueContainer }}
                            id="bank_name"
                            name="bank_name"
                            options={modified_bank_list}
                            placeholder={t("INCENTIVE_PAYMENT.UPDATE_PAYMENT.BANK_NAME")}
                            className="react-select"
                            classNamePrefix="react-select"
                            onChange={(v) => changeHandler('bank_name', v.value)}
                            value={formData.bank_name ? modified_bank_list.filter(({ value }) => value === formData.bank_name) : ""}
                            getOptionLabel={({ display_bank_name }) => display_bank_name}
                        />
                    </div>
                </fieldset>

                <fieldset className={errors.utr_number ? 'form-filed col-md-6 required-msg' : 'form-filed col-md-6'}>
                    <div className="material">
                        <input
                            id="utr_number"
                            type="text"
                            placeholder=" "
                            name="utr_number"
                            className="form-input"
                            value={formData.utr_number || ""}
                            onChange={(v) => changeHandler('utr_number', v.target.value)}

                        />
                        <label data-label={t("INCENTIVE_PAYMENT.UPDATE_PAYMENT.UTR_NUMBER")} className="form-label"></label>
                    </div>
                </fieldset>
                <fieldset className={errors.payment_date ? 'form-filed col-md-6 required-msg' : 'form-filed col-md-6'}>
                    <div className="material">
                        <DatePicker
                            id="payment_date"
                            name="payment_date"
                            peekNextMonth
                            showTimeSelect={true}
                            popperPlacement="top"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            autoComplete="off"
                            placeholderText={t("INCENTIVE_PAYMENT.UPDATE_PAYMENT.PAYMENT_DATE")}
                            selected={formData.payment_date ? new Date(formData.payment_date) : new Date()}
                            onChange={(v) => changeHandler('payment_date', v)}
                            minTime={new Date().setHours(0,0,0)}
                            maxTime={maxTime}
                            maxDate={(new Date())}
                            onKeyDown={(e) => e.preventDefault()}
                            dateFormat={DATE_FORMAT_INPUT_TIME}
                        />
                    </div>
                </fieldset>
                {hasAccessPermission('incentive-payment', 'edit') ?
                    <div className="col-md-12">
                        <button disabled={isPaymentUpdating ? 'disabled' : ""} className="btn-primary" onClick={handleSubmit} >{t("INCENTIVE_PAYMENT.UPDATE_PAYMENT.UPDATE")}</button>
                    </div> : null}
            </div>

        </div>

    )

}
export default UpdatePayment;