import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import dateFormat from 'dateformat';
const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".csv";
const SingleExcelExport = (list, fileName = 'Inbound Records Pending Format', sheetName = 'Allocation Pending records') => {
    const singleTabFile = (list, fileName, sheetName) => {
        let dataArray = list;
        const ws = XLSX.utils.json_to_sheet(dataArray);
        ws["!cols"] = [{ width: 20 }, { width: 25 }, { width: 30 }, { width: 15 }, { width: 15 }, { width: 50 }];
        ws['!rows'] = Array(dataArray.length).fill({ hpt: 18 });

        const wb = { Sheets: { [sheetName]: ws }, SheetNames: [sheetName] };
        const excelBuffer = XLSX.write(wb, { bookType: 'csv', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + '-' + dateFormat('dd mmm yyyy h:MM:ss TT') + '-PB' + fileExtension);
    };
    return singleTabFile(list, fileName, sheetName);
    // return (
    //     <button className="btn-line" onClick={(e) => singleTabFile(list, 'Inbound Records Pending Format', 'Allocation Pending records')}><i className="ic-export"></i> <span>Export</span></button>
    // );
}
export default SingleExcelExport;