import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import secureStorage from "../../config/encrypt";
import { userDetail } from "../../store/allAction";
import store from '../../store/store';

class AccessDenied extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	updateReduxData = async () => {
		var loginResponse = secureStorage.getItem("authUserInfo");
		if (loginResponse) {
			store.dispatch(userDetail(loginResponse));
		} else {
			this.setState({ redirect: "/" });
		}
	};

	componentDidMount = async () => {
		await this.updateReduxData();
	};

	render() {
		return (
			<div className="container-fluid">
				<div className="access-denied-container">
					<h1>{this.props.t("COMMON.DONT_HAVE_PERMISSION_TO_ACCESS_PAGE")}</h1>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return { user_loggedin: state.userLoggedIn, userData: state.userData };
};
export default withTranslation("language")(connect(mapStateToProps, null)(AccessDenied)
);
