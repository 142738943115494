import React from "react";
import Navigation from "./Navigation";
import { useNavigate } from "react-router-dom";
const Header = () => {
	const navigate = useNavigate();
	return (
		<header>
			<Navigation navigate={navigate}/>
		</header>
	);
};

export default Header;
