import MasterService from "./MasterService";

const serviceList = {
	...MasterService,
	// THIS IS SAMPLE SERVICE FUNCTION
	userLogin(body) {
		return this.post("user/login", body);
	},
	userOtpSend(body) {
		return this.post("user/send-otp", body);
	},
	userLogOut(body) {
		return this.post("user/logout", body);
	},
	getSalaryReportList(body){
		return this.post("/salary-report/get_salary_report",body);
	},
	addSalaryPayment(body){
		return this.post("/salary-report/add_salary_payment",body);
	},
	uploadSalaryReport(body){
		return this.put("/salary-report/upload_salary_report",body);
	},
	getAllUserRolesList(body) {
		return this.post("user/user_roles", body);
	},
	getAllUserList(body) {
		return this.post("user/user_list", body);
	},
	saveRole(body) {
		return this.post("user/save_role", body);
	},
	saveUser(body) {
		return this.post("user/save_user", body);
	},
	featureList(body) {
		return this.post("user/feature_list", body);
	},
	updateRoleStatus(body) {
		return this.post("user/role_status_update", body);
	},
	updateUserLoginStatus(body) {
		return this.post("user/status-update", body);
	},
	getIncentivePayment(body) {
		return this.post("incentive/get_incentive_payment_list", body);
	},
	updateAssignedUser(body) {
		return this.post("incentive/update_assigned_user", body);
	},
	verifyIncentivePayment(body) {
		return this.post("incentive/verify_incentive_payment", body);
	},
	savePaymentDetails(body) {
		return this.post("/incentive/save_payment", body);
	},
	syncBankInfo(body) {
		return this.post("/incentive/sync_bankinfo", body);
	}
};

export default serviceList;
