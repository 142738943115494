import secureStorage from "../../config/encrypt";
let authUser = "";
let initialState = "";
try {
	authUser = secureStorage.getItem("authUserInfo");
	initialState =
		authUser ? { loggedIn: true, authUser, authUser } : {};
} catch (err) {
}

const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case "ADD_USER_INFO":
			return {
				...state,
				loggedIn:true,
				userDetail: action.payload,
			};

		// case "UPDATE_NOTIFICATION":
		// 	return {
		// 		...state,
		// 		listNotify: action.payload,
		// 	};
		// case "UPDATE_UNSEEN_NOTIFICATION":
		// 	return {
		// 		...state,
		// 		totalUnseenNotify: action.payload,
		// 	};
		case "USER_LOGIN_STATUS":
			return {
				...state,
				loggedIn:true,
				loginStatus: action.payload,
			};
		case "LOGOUT":
			return {
				loggedIn: false,
				userDetail:{},
				loginStatus:{}
			};
		default:
			return state;
	}
};

export default userReducer;
