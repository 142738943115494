import dateFormat from 'dateformat';
import { DATE_FORMAT_DMY, CURRENCY_INFO, BANK_LIST_OBJECTS } from "../../config/constant";
export const localeNumber = (str) => {
    let term = ','
    let replacement = '.';
    return str.toLocaleString().replace(RegExp(term.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), 'g'), replacement);
};

export const strToNumber = (str) => {
    str = str.toString();
    let amount = +(str && str.replaceAll('.', '').replace(',', '.')) || 0;
    return +(amount && amount.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]) || 0;
};

export const numberFormatWithDots = (str) => {
    if(str){
        str = str.toString().split('.');
        let thousandsGroupRegex = /(\d)(?=(\d{3})+(?!\d))/g;
        let index = str[0].search(/[1-9]/);
        index = index === -1 ? str[0].length : index;
        return (
            str[0].substring(0, index) +
            str[0].substring(index, str[0].length).replace(thousandsGroupRegex, "$1.") +
            (str[1] !== undefined ? ',' + str[1].substring(0, 2) : '')
        );
    }
    else{
        return 0;
    }
};

export const inputNumberFormat = (str) => {
    if (typeof str === 'number') str = str.toString().split('.');
    else str = str.split(',');
    let thousandsGroupRegex = /(\d)(?=(\d{3})+(?!\d))/g;
    let firstString = str[0].replace(/\D/g, '')
    let index = firstString.search(/[1-9]/);
    index = index === -1 ? firstString.length : index;
    return (
        firstString.substring(0, index) +
        firstString.substring(index, firstString.length).replace(thousandsGroupRegex, "$1.") +
        (str[1] !== undefined ? ',' + str[1].substring(0, 2) : '')
    );
};

export const displayDate = (d,format=DATE_FORMAT_DMY) => {
    return d ? dateFormat(d, format) : ""
}
export const uniqueArr = (arrayOfObj, key) => {
    return arrayOfObj.filter((item, index, array) => {
        //return array.map((mapItem) => mapItem[key]).indexOf(item[key]) === index
        return array.map((mapItem) => mapItem[key] === item[key] && mapItem.lead_source === item.lead_source)
    })
}
export const numberFormatWithLocale = (number, amount_format_type = 'en') => {
    let flt = number % 1 === 0 ? ',00' : '';
    return amount_format_type === 'id' ? `${Number(number).toLocaleString('id-ID')}${flt}` : `${Number(number).toLocaleString('en-IN')}`;
}

