import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Calendra() {
    // const [value, onChange] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());
    return (
        <div className="datepicker">
             <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            showYearPicker
            dateFormat="yyyy"
            yearItemNumber={9}
            />
        </div>
    );
}
function Calendra1(props) {
    // const [value, onChange] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());

    return (
        <div className="datepicker">
            <DatePicker
               {...props}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText=" To Date "
            />
            {/* <label data-label="Date/Time" class="form-label"></label> */}
        </div>
    );
}

function Calendra2(props) {
    // const [value, onChange] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());

    return (
        <div className="datepicker">
            <DatePicker
                {...props}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="From Date"
            />
        </div>
    );
}



function YearPicker() {
    // const [value, onChange] = useState(new Date());
   const [startDate, setStartDate] = useState(new Date());
    return (
        <div className="datepicker">
            <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                showYearPicker
                dateFormat="yyyy"
                placeholderText=""
            />
        </div>
    );
}

function DOJ(props) {
    // const [value, onChange] = useState(new Date());
   const [startDate, setStartDate] = useState(new Date());
   let {  onDateSelect, maxDate = "", minDate = ""} = props;

   const  keyboardInput = (e) => {
    e.target.value = e.target.value && e.target.value.replace(/[^0-9\/]/g, '') || "";
}

   const onBlurHandler = (e) => {
    /** Checking for date format and min max validation */
    if (e.target.value) {
        let date_regex = /^\d{1,2}\/\d{1,2}\/\d{4}$/g;

        let [ date, month ,year] = e.target.value.split('/');
        if (e.target.value.match(date_regex)) {

            /** Validating month and date values */
            if (
              (Number(month) > 12 || Number(month) < 1) ||
              (Number(date) > 31 || Number(date) < 1)
            ) {
              onDateSelect(""); // Clearing date
            }
        } else {
            onDateSelect(""); // Clearing date
        }
        let selectedDate = [month,date,year].join('/')
        
        if (new Date(selectedDate) > new Date(maxDate)) {
            onDateSelect(""); // Clearing date
        } else if (new Date(selectedDate) < new Date(minDate)) {
            onDateSelect(""); // Clearing date
        } 
    }
}
    return (
        <div className="datepicker">
            <DatePicker
                {...props}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                allowKeyDown={true}
                placeholder="mm/dd/yyyy"
                dropdownMode="select"
                placeholderText="DOJ*"
                dateFormat= 'dd/MM/yyyy'
                autoComplete='off'
                onKeyDown={e => keyboardInput(e)}
                onBlur={e => onBlurHandler(e)}
            />
            {/* <label data-label="Date/Time" class="form-label"></label> */}
        </div>
    );
}

function SalaryPeriod(props) {
    // const [value, onChange] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());
    let {  onDateSelect, maxDate = "", minDate = ""} = props;

    const  keyboardInput = (e) => {
     e.target.value = e.target.value && e.target.value.replace(/[^0-9\/]/g, '') || "";
 }
 
    const onBlurHandler = (e) => {
     /** Checking for date format and min max validation */
     if (e.target.value) {
         let date_regex = /^\d{1,2}\/\d{4}$/g;
 
         let [month ,year] = e.target.value.split('/');
         if (e.target.value.match(date_regex)) {
 
             /** Validating month and date values */
             if (
               (Number(month) > 12 || Number(month) < 1)
             ) {
               onDateSelect(""); // Clearing date
             }
         } else {
             onDateSelect(""); // Clearing date
         }
         let selectedDate = [month,year].join('/')
         
         if (new Date(selectedDate) > new Date(maxDate)) {
             onDateSelect(""); // Clearing date
         } else if (new Date(selectedDate) < new Date(minDate)) {
             onDateSelect(""); // Clearing date
         } 
     }
 }
    return (
        <div className="datepicker">
            <DatePicker
            {...props}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              autoComplete='off'
              onKeyDown={e => keyboardInput(e)}
              onBlur={e => onBlurHandler(e)}
      />
            {/* <label data-label="Date/Time" class="form-label"></label> */}
        </div>
    );
}





export {YearPicker,Calendra1,Calendra2,DOJ, SalaryPeriod}