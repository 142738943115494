import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import AccessDenied from "../view/notfound/AccessDenied";
import Header from "../view/common/Header";
import Login from "../view/user/Login";
import LoginOtp from "../view/user/LoginOtp";
// check for Authorized access 
import { hasAccessPermission } from "../config/constant";
import secureStorage from "../config/encrypt";
import PageNotFound from "../view/notfound/PageNotFound";
import SalaryReport from "../view/salary-report/SalaryDataReport";
import UserRoleManagement from "../view/user/UserRoleManagement";
import IncentiveTab from "../view/incentive-payment/IncentiveTab";
class CustomRouter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			ht: 500,
			loggedIn: false
		};
	}

	static getDerivedStateFromProps = (nextProps, state) => {
		const updatedProps = {};
		if (
			nextProps.loggedIn !== state.loggedIn
		) {
			updatedProps["loggedIn"] = nextProps.loggedIn;
		}
		return updatedProps
	};

	componentDidMount = async () => {
		this.setState({ ht: window.innerHeight - 107 });
		window.addEventListener("resize", () => {
			this.setState({ ht: window.innerHeight - 107 });
		}, false);
	}
	render() {
		var loginResponse = secureStorage.getItem("authUserInfo");
		let is_access_role = (loginResponse && loginResponse.user_data && loginResponse.user_data.is_access_role && +loginResponse.user_data.is_access_role === 1) ? true : false
		return (
			<Router>
				{loginResponse ? <Header /> : null}
				<Routes>
					<Route exact path="/" element={<Login />} />
					<Route exact path="/login" element={<Login />} />
					<Route exact path="/logout" element={<LoginOtp />}/>
					<Route exact path="/salary-report"  element={(hasAccessPermission('salary-report')) ? <SalaryReport /> : <AccessDenied />} />
					<Route exact path="/dashboard"  element={(hasAccessPermission('salary-report')) ? <SalaryReport /> : <AccessDenied />} />
					<Route exact path="/user-role" element={ is_access_role ? <UserRoleManagement /> : <AccessDenied />} />
					<Route path="/incentive/:tab/:subtab" element={(hasAccessPermission('incentive-payment')) ? <IncentiveTab /> : <AccessDenied />} />
					<Route path='**' component={<PageNotFound />} />
				</Routes>
			</Router>
		);
	}
}

const mapStateToProps = ({ user }) => {
	return {
		loggedIn: user.loggedIn
	};
};
export default connect(mapStateToProps, null)(CustomRouter);

//export default Routes;
