import React, { Component } from "react";

class InputField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      type: "",
      name: "",
      label: "",
      dataerror: "",
      maxLength: "",
      className: "",
      value: "",
      onChange: ""
    };
  }

  changeHandel = event => {
    if (typeof this.props.onChange === "function") {
      this.props.onChange(event);
    }
  };

  static getDerivedStateFromProps = (nextProps, prevState) => {
    return ({
      value: nextProps.value
    });
  }

  chkValBlur = event => {
    const { value, id } = event.target;
    let errorElement = document
      .getElementById(id)
      .parentNode.querySelector(".error");
    let formField = document.getElementById(id).closest(".material");
    if (value === "") {
      if (errorElement) errorElement.classList.remove("hide");
      if (formField) formField.classList.remove("active");
    } else {
      if (errorElement) errorElement.classList.add("hide");
      if (formField) formField.classList.add("active");
    }
  };
  chkValFocus = event => {
    const { id } = event.target;
    let formField = document.getElementById(id).closest(".material");
    if (formField) formField.classList.add("active");
  };

  render() {
    let props = this.props;
    return (
      <div className="material">
        {props.inputProps.type === "checkbox" ||
          props.inputProps.type === "radio" ? (
            <label
              htmlFor={props.inputProps.id}
              className="gs_control gs_checkbox"
            >
              {props.inputProps.label}
              <input
                {...props.inputProps}
                className="form-input"
                autoComplete="new-off"
                onChange={this.changeHandel}
              />
              <span className="gs_control__indicator"></span>
            </label>
          ) : (
            <div>
              <input
                {...props.inputProps}
                onBlur={this.chkValBlur}
                onFocus={this.chkValFocus}
                onChange={this.changeHandel}
                autoComplete="new-off"
                className={
                  props.inputProps.className
                    ? props.inputProps.className
                    : "form-input"
                }
              />
              {props.inputProps.label ? <label data-label={props.inputProps.label} className="form-label"></label> : ''}
              {props.inputProps.dataerror ? (
                <span className="error-msg">{props.inputProps.dataerror}</span>
              ) : (
                  ""
                )}
              <label
                data-label={props.placeholder || ""}
                className="form-label"
              ></label>
            </div>
          )}

      </div>
    );
  }
}

export default InputField;
