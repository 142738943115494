import { useEffect, useState } from 'react';
import InputField from '../../elements/InputField';
import MultiSelect from '../../elements/MultiSelect';
import Select from 'react-select';
import PaymentService from '../../../service/paymentService';
import { hasAccessPermission } from "../../../config/constant";
const statusArr = [
    { value: "1", label: "Active" },
    { value: "0", label: "Inactive" }
];
function ManageUser(props) {
    const [userData, setUserData] = useState(props.userForm);
    const [userError, setUserError] = useState({});
    const [userPermissions, setUserPermissions] = useState([]);
    const [roles, setRoles] = useState([]);
    const mobile_regex = /^[0-9]{9,12}$/;
    const email_regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const password_regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/

    useEffect(() => {
        if (props.formModalStatus) {
            setUserData(props.userForm);
            setUserError({});
            getAllUserRoles(props.userForm.id);
        }
    }, [props.formModalStatus]);

    const getAllUserRoles = async (user_id) => {
        await PaymentService.getAllUserRolesList({ user_id })
            .then(response => {
                if (response.status === 200 && response.data.status === 200) {
                    let { data } = response.data;
                    setRoles(data.role_lists);
                    setUserPermissions(data.permissions ? data.permissions : [])
                }
            })
    }

    const changeHandler = (n, e) => {
        let v = '';
        if (n == 'role_ids') {
            v = e.map((obj) => obj.id);
        }
        else if (n == 'mobile') {
            v = Number(e.target.value.replace(/\D/g, ''));
        }
        else if (n == 'status') {
            v = e.target.checked ? "1" : "0";
        }
        else {
            v = e.target.value;
        }
        setUserData((prev) => ({
            ...prev,
            [n]: v
        }))
        setUserError((prev) => ({
            ...prev,
            [n]: v ? "" : "Error"
        }))
    }
    const isValid = () => {
        let { name, email, password, role_ids, mobile, id = "" } = userData;
        if (!name) userError.name = 'Name should not blank'; else userError.name = '';
        if(email){
            if (!email_regex.test(email)) userError.email = 'Email is not valid'; else userError.email = '';
        }else{
            userError.email = 'Email should not blank';
        }
        if (id == "" && (!password)) userError.password = 'Password should not blank'; else userError.password = '';
            
        if(password){ 
           if ((!password_regex.test(password))) userError.password = 'Password should contain atleast 8 character with 1 special char , 1 Uppercase , 1 lowercase and 1 digit'; else userError.password = '';
        }
       
        if (!role_ids || role_ids.length < 1) userError.role_ids = 'Error'; else userError.role_ids = '';

        if(mobile){
            if (!mobile_regex.test(mobile)) userError.mobile = 'Mobile number is not valid'; else userError.mobile = '';
        }else{
            userError.mobile = 'Mobile number should not blank';
        }

        if (userError.name || userError.email || userError.password || userError.role_ids || userError.mobile) {
            setUserError({ ...userError })
            return false;
        }
        return true;
    }
    const submitUserForm = (e) => {
        if (isValid()) {
            props.submitForm(userData);
        }
    }
    return (
        <>
            {!userData.id ? null : <div className="row m-md-t">
                <fieldset className="form-filed col-md-10 e-none">
                    <InputField
                        inputProps={{
                            value: props.selectedUserRole || ''
                        }}
                        placeholder={props.t('USER_ROLE.ASSIGNED_ROLE_NAME')}
                    />
                </fieldset>
                <fieldset className="form-filed col-md-2">
                    <label>{userData.status == 1 ? "Active" : "Inactive"}</label>
                    <label className="switch">
                        <input type="checkbox" onChange={changeHandler.bind(this, 'status')} defaultChecked={userData.status == 1 ? true : false} />
                        <span className="slider round"></span>
                    </label>
                </fieldset>
            </div>
            }
            <fieldset className={userError.role_ids ? "required-msg executive-dropdown multiselect-dropDown width" : "executive-dropdown multiselect-dropDown width"}>
                <div className="material">
                    <label className="form-label">Role</label>
                    <MultiSelect
                        options={roles}
                        values={userData.role_ids}
                        onChange={changeHandler}
                        name="role_ids"
                        placeholder={props.t("USER_ROLE.USER_ROLE")}
                        optionLabel='role_name'
                        optionValue='id'
                    />
                </div>
            </fieldset>
            <fieldset className={userError.name ? "required-msg m-lg-t" : "m-lg-t"}>
                <div className="material">
                    <label className="form-label"></label>
                    <InputField
                        inputProps={{
                            id: "name",
                            className: "form-input",
                            type: "text",
                            name: "name",
                            placeholder: " ",
                            value: userData.name || '',
                            dataerror : userError.name
                        }}
                        onChange={changeHandler.bind(this, 'name')}
                        placeholder={props.t('USER_ROLE.NAME') + '*'}
                    />
                </div>
            </fieldset>
            <fieldset className={userError.email ? "required-msg" : ""}>
                <div className="material">
                    <label className="form-label"></label>
                    <InputField
                        inputProps={{
                            id: "email",
                            className: "form-input",
                            type: "text",
                            name: "email",
                            placeholder: " ",
                            value: userData.email || '',
                            dataerror : userError.email
                        }}
                        onChange={changeHandler.bind(this, 'email')}
                        placeholder={props.t('USER_ROLE.EMAIL') + '*'}
                    />
                </div>
            </fieldset>
            <fieldset className={userError.mobile ? "required-msg" : ""}>
                <div className="material">
                    <label className="form-label"></label>
                    <InputField
                        inputProps={{
                            id: "mobile",
                            className: "form-input",
                            type: "text",
                            name: "mobile",
                            placeholder: " ",
                            value: userData.mobile || '',
                            dataerror : userError.mobile
                        }}
                        onChange={changeHandler.bind(this, 'mobile')}
                        placeholder={props.t('USER_ROLE.MOBILE_NO') + '*'}
                    />
                </div>
            </fieldset>
            <fieldset className={userError.password ? "required-msg" : ""}>
                <div className="material">
                    <label className="form-label"></label>
                    <InputField
                        inputProps={{
                            id: "password",
                            className: "form-input",
                            type: "pasword",
                            name: "password",
                            placeholder: " ",
                            minLength: "5",
                            value: userData.password || '',
                            dataerror : userError.password
                        }}
                        onChange={changeHandler.bind(this, 'password')}
                        placeholder={props.t('USER_ROLE.PASSWORD') + '*'}
                    />
                </div>
            </fieldset>
            <div className="submit-btn m-md-t">
                <button className="btn-primary" disabled={props.isSubmit ? 'disabled' : null} onClick={submitUserForm}>Submit</button>
            </div>
        </>
    )
}
export default ManageUser;