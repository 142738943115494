import React from "react";
import {createRoot} from "react-dom/client";
import App from "./App";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import language_id from "./translations/id/language.json";
import language_en from "./translations/en/language.json";
import secureStorage from "./config/encrypt";

import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store/store";
import { userDetail } from './store/allAction';

i18next.init({
	interpolation: { escapeValue: false },
	lng: secureStorage.getItem("lang") === "id" ? "id" : "en",
	resources: {
		en: {
			language: language_en,
		},
		id: {
			language: language_id,
		},
	},
});
let loginInfo = secureStorage.getItem("authUserInfo");
if(loginInfo && loginInfo.user_data) store.dispatch(userDetail(loginInfo));

const root = createRoot(document.getElementById("root"));
root.render(
    // <React.StrictMode>
		<I18nextProvider i18n={i18next}>
        <Provider store={store}>
                <App />
        </Provider>
		</I18nextProvider>
    // </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
