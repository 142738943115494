import { useEffect, useState } from 'react';
import InputField from '../../elements/InputField';
import { hasAccessPermission } from "../../../config/constant";
let intialPermission = { feature_id: "0", view: "0", add: "0", edit: "0", delete: "0" };
function ManageRole(props) {
    const [roleData, setRoleData] = useState(props.roleForm);
    const [permissions, setPermissions] = useState(props.roleFeatures);
    const [roleError, setRoleError] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    useEffect(() => {
        if (props.formModalStatus == 'roleModal') {
            setRoleData(props.roleForm);
            setPermissions(props.roleFeatures);
            setIsSubmit(false);
        }
    }, [props])
    const changeHandler = (n, e) => {
        setRoleData((prev) => ({
            ...prev,
            [n]: e.target.value
        }))
        if (n == 'role_name') {
            setRoleError((prev) => ({
                ...prev,
                [n]: e.target.value ? "" : "Error"
            }))
        }
    }

    const changeFeatureHandler = (i, id, e, fm) => {
        if (e.target.checked) {
            if (fm == 'feature_id') {
                permissions[i][fm] = id;
                permissions[i]['access_type'] = "view";
            }
            else {
                permissions[i]['access_type'] = fm;
            }
        }
        else {
            permissions[i]['feature_id'] = "0";
            permissions[i]['access_type'] = "";
        }
        setPermissions([...permissions]);
    }
    const isValid = () => {
        let { role_name } = roleData;
        if (!role_name) roleError.role_name = 'Error'; else roleError.role_name = '';
        if (roleError.role_name) {
            setRoleError({ ...roleError })
            return false;
        }
        return true;
    }
    const submitRole = (e) => {
        if (isValid()) {
            setIsSubmit(true);
            let permissionArr = [...permissions];
            roleData.permissions = [];
            permissionArr.map(per => {
                if (per.feature_id != 0) {
                    roleData.permissions.push({ feature_id: per.feature_id, access_type: per.access_type })
                    return per;
                }
            });
            roleData.description = roleData.description ? roleData.description : "";
            props.submitRole(roleData);
        }
    }
    return (
        <>
            <fieldset className={roleError.role_name ? "required-msg m-lg-t" : "m-lg-t"}>
                <div className="material">
                    <label className="form-label"></label>
                    <InputField
                        inputProps={{
                            id: "role_name",
                            className: "form-input",
                            type: "text",
                            name: "role_name",
                            placeholder: " ",
                            value: roleData.role_name || ''
                        }}
                        onChange={changeHandler.bind(this, 'role_name')}
                        placeholder={props.t('USER_ROLE.ROLE_NAME') + '*'}
                    />
                </div>
            </fieldset>
            <fieldset className="from-group">
                <div className="material">
                    <textarea placeholder={props.t("USER_ROLE.DESCRIPTION")}
                        rows="5"
                        className="form-input"
                        value={roleData.description || ""}
                        name="description"
                        onChange={changeHandler.bind(this, 'description')}>
                    </textarea>
                </div>
            </fieldset>
            <div className="role-feature-list">

                {
                    permissions && permissions.map((rf, i) => {
                        return (
                            <div key={rf.id + '_rolelist'} className="add-role-list">
                                <div className="custom-control custom-checkbox">
                                    <input id={'ck_' + rf.id} type="checkbox" defaultChecked={rf.feature_id != 0 ? true : false} onChange={(e) => changeFeatureHandler(i, rf.id, e, 'feature_id')} className="custom-control-input" value={rf.id} />
                                    <label htmlFor={'ck_' + rf.id} className="custom-control-label">{rf.name}</label>
                                </div>
                                {rf.feature_id != 0 ?
                                    <div className="user-permission">
                                        <div className="input-radio">
                                            <input name={'acct_' + rf.id + '_name'} id={'view_' + rf.id} type="radio" checked={rf.access_type == "view" ? true : false} onChange={(e) => changeFeatureHandler(i, rf.id, e, 'view')} />
                                            <label htmlFor={'view_' + rf.id} className="radio-label">View</label>
                                        </div>
                                        <div className="input-radio">
                                            <input name={'acct_' + rf.id + '_name'} id={'edit_' + rf.id} type="radio" checked={rf.access_type == "edit" ? true : false} onChange={(e) => changeFeatureHandler(i, rf.id, e, 'edit')} />
                                            <label htmlFor={'edit_' + rf.id} className="radio-label">Can Edit</label>
                                        </div>
                                    </div> : null
                                }

                            </div>
                        )
                    })
                }

            </div>
            {permissions.length < 1 || isSubmit ? <div className="loader loader-default is-active loader-main" data-text="loading"></div> : null}
            {hasAccessPermission('user-role', 'edit') ?
                <div className="submit-btn m-md-t">
                    {isSubmit ? <button className="btn-primary" disabled>{props.t("USER_ROLE.SAVE")}</button> :
                        <button className="btn-primary" onClick={submitRole}>{props.t("USER_ROLE.SAVE")}</button>}
                </div> : null}
        </>
    )
}
export default ManageRole;