import { useTranslation } from "react-i18next";
const PageNotFound = () => {
    const { t } = useTranslation('language');
    return (
        <div className="container-fluid">
            <div className="access-denied-container">
                <h1>{t("Look like you're lost the page, you are looking for not avaible!")}</h1>
            </div>
        </div>
    );
}
export default PageNotFound;