import { useTranslation } from "react-i18next";
import Select from "react-select";
import { hasAccessPermission } from "../../../config/constant";
const statusArr = [
    { value: "1", label: "Active" },
    { value: "0", label: "Inactive" }
];
function UserList({ users, isLoading, userAction }) {
    const { t } = useTranslation('language');
    return (
        <div className="loan-table-data">
            <table>
                <thead>
                    <tr>
                        <th>{t('USER_ROLE.NAME')}</th>
                        <th>{t('USER_ROLE.EMAIL')}</th>
                        <th>{t('USER_ROLE.MOBILE_NO')}</th>
                        <th>{t('USER_ROLE.ROLE_ASSIGNED')}</th>
                        <th>{t('USER_ROLE.STATUS')}</th>
                        <th>{t('USER_ROLE.ACTION')}</th>
                    </tr>
                </thead>
                <tbody>
                    {!isLoading && users.length ? users.map((user) => {
                        return (
                            <tr key={user.mobile}>
                                <td>{user.name}</td>
                                <td>{user.email}</td>
                                <td>{user.mobile}</td>
                                <td>{user.all_role}</td>
                                <td>
                                    <Select
                                        onChange={(e) => { userAction(user, 'delete', user.status == '1' ? "0" : "1") }}
                                        options={statusArr}
                                        placeholder={t("USER_ROLE.STATUS")}
                                        value={user.status ? statusArr.filter(({ value }) => value == user.status) : ""}
                                        isDisabled={hasAccessPermission('user-role', 'edit') ? false : true}
                                    />
                                </td>
                                <td>
                                    {hasAccessPermission('user-role', 'edit') ?
                                        <div className="d-flex">
                                            <button className="btn-view" onClick={() => { userAction(user, 'delete') }}>{t('USER_ROLE.DELETE')}</button>&nbsp;
                                            <button className="btn-view" onClick={() => { userAction(user, 'edit') }}>{t('USER_ROLE.EDIT')}</button>
                                        </div> :
                                        <div className="d-flex">
                                            <button className="btn-view" disabled>{t('USER_ROLE.DELETE')}</button>&nbsp;
                                            <button className="btn-view" disabled>{t('USER_ROLE.EDIT')}</button>
                                        </div>
                                    }
                                </td>
                            </tr>
                        )
                    }) : <tr>
                        <td colSpan="6" className="txt-center">{isLoading ?
                            <div className="loader loader-default is-active loader-main" data-text="loading"></div> :
                            t('USER_ROLE.NOT_FOUND')}
                        </td>
                    </tr>
                    }
                </tbody>
            </table>
        </div >
    )
}
export default UserList;