import React, { useEffect, useState } from 'react';
import SimpleSlider from "./DocumentSlider"
import { useTranslation } from "react-i18next";
import Modal from "../elements/Modal"
import UpdatePayment from "./UpdatePayment"
import InfiniteScroll from 'react-infinite-scroll-component';
import Select from 'react-select';
import { numberFormatWithDots, displayDate } from "../common/Helper";
import { hasAccessPermission, BANK_LIST, INCENTIVE_TYPE } from "../../config/constant";
import secureStorage from "../../config/encrypt";
import Loader from '../elements/Loader';

const IncentiveTable = (props) => {
    const { t } = useTranslation("language");
    const [reportData, setReportData] = useState(props.reportData)
    const [displayLoanID, setDisplayLoanID] = useState(0);
    const [showDocumentView, setDocumentView] = useState(false);
    const [showUpdatePopup, setUpdatePopup] = useState(false);
    const [docObj, setDocObj] = useState({});
    const [paymentData, setPaymentData] = useState({});

    /** Get Logedin user data */
    const loggedInUser = secureStorage.getItem("authUserInfo");
    const user_id = (loggedInUser.user_data && loggedInUser.user_data.user_id) || 0;
    const role_id = (loggedInUser.user_data && loggedInUser.user_data.role_id) || 0;
    const logged_id_user_role = role_id ? (role_id.includes('2') ? 'approver' : 'maker') : '';

    useEffect(() => {
        setReportData([...props.reportData]);
    }, [props.reportData])
    useEffect(() => {
        setDisplayLoanID(0);
    }, [props.activeTab])
    const toggleTable = (loandId) => {
        setDisplayLoanID(displayLoanID == loandId ? "" : loandId)
    }
    const toggleUpdatePaymentView = (isShow, data) => {
        data.amount_to_be_paid = data.payment_amount
        setPaymentData(data);
        setUpdatePopup(isShow);
        if (isShow) {
            document.body.classList.add("overflow-hidden");
        }
        else {
            // setDisplayLoanID("");
            document.body.classList.remove("overflow-hidden");
        }
    }
    const paymentUpdated = () => {
        // setDisplayLoanID("");
        setPaymentData({});
        setUpdatePopup(false);
        props.paymentUpdated(true);
        document.body.classList.remove("overflow-hidden");
    }
    const toggleDocumentView = (isShow, docs = {}) => {
        setDocObj(docs);
        setDocumentView(isShow)
        if (isShow) document.body.classList.add("overflow-hidden");
        else document.body.classList.remove("overflow-hidden");
    }

    const syncBankInfo = async (incentiveData) => {
        props.syncBankInfo(incentiveData)
    }
return (
        <div id="scrollableDiv" >
            <InfiniteScroll
                dataLength={props.reportData.length}
                next={props.loadMoreItems}
                hasMore={props.hasMoreItems || false}
            // loader={props.loading || props.isFilter ? <div className="loader loader-default is-active loader-main" data-text="loading"></div> : null}
            >
                <div className={`payment-pending-table ${props.activeTab} === 'pending' ? incentive-pending-table : incentive-paid-table`}>
                    <div className='table-scroll-data'>
                        <table>
                            <thead>
                                <tr>
                                    <th>{t("INCENTIVE_PAYMENT.SFA_ID")}</th>
                                    <th>{t("INCENTIVE_PAYMENT.USER_NAME")}</th>
                                    <th>{t("INCENTIVE_PAYMENT.USER_ROLE")}</th>
                                    <th>{t("INCENTIVE_PAYMENT.NPWP_NUMBER")}</th>
                                    {props.activeTab === 'pending' ?
                                        <th>{t("INCENTIVE_PAYMENT.AMOUNT_TO_BE_PAID")}</th>
                                        :
                                        <th>{t("INCENTIVE_PAYMENT.AMOUNT_PAID")}</th>
                                    }
                                    <th>{t("INCENTIVE_PAYMENT.BANK_NAME")}</th>
                                    <th>{t("INCENTIVE_PAYMENT.BRANCH_NAME")}</th>
                                    <th>{t("INCENTIVE_PAYMENT.ACCOUNT_NAME")}</th>
                                    <th>{t("INCENTIVE_PAYMENT.BENEFICIARY_NAME")}</th>
                                    <th>{t("INCENTIVE_PAYMENT.INCENTIVE_TYPE")}</th>
                                    <th>{t("INCENTIVE_PAYMENT.INCENTIVE_PERIOD")}</th>



                                    <th>{t("INCENTIVE_PAYMENT.RELATED_DOCUMENTS")}</th>
                                    {props.activeTab === 'pending' ?
                                        <th>{t("INCENTIVE_PAYMENT.ASSIGNED_TO")}</th>
                                        :
                                        <>
                                            <th>{t("INCENTIVE_PAYMENT.OTO_BANK")}</th>
                                            <th>{t("INCENTIVE_PAYMENT.PAYMENT_PAID_DATE")}</th>
                                            <th>{t("INCENTIVE_PAYMENT.UPDATED_DATE")}</th>
                                            <th>{t("INCENTIVE_PAYMENT.UTR_NUMBER")}</th>
                                        </>}
                                    <th>{t("INCENTIVE_PAYMENT.MAKER")}</th>
                                    <th>{t("INCENTIVE_PAYMENT.APPROVER")}</th>

                                    {props.activeTab === 'pending' ?
                                        <th>{t("INCENTIVE_PAYMENT.ACTION")}</th> : null}

                                </tr>
                            </thead>
                            <tbody>
                                {reportData && reportData.length ? (
                                    reportData.map((current_obj,index) => (
                                        <React.Fragment
                                            key={
                                                current_obj.incentive_id + "_incentive_id"
                                            }
                                        >
                                            <tr key={
                                                current_obj.incentive_id + '-'+index
                                            }>
                                                <td>{current_obj.sfa_user_id || ''}</td>
                                                <td><span className='line-clamp line-clamp-2' title={current_obj.sfa_user_name || ""}>{current_obj.sfa_user_name || ''}</span></td>
                                                <td>{current_obj.sfa_user_role || ''}</td>
                                                <td>{current_obj.npwp_number ?  String(current_obj.npwp_number).replace(/(\d{2})(\d{3})(\d{3})(\d{1})(\d{3})(\d{3})/, '$1.$2.$3.$4-$5.$6') : ''}</td>
                                                <td>{numberFormatWithDots(current_obj.payment_amount || 0) || ''}</td>
                                                <td> <h5 ><span className='line-clamp line-clamp-2' title={current_obj.bank_name || ""}>{current_obj.bank_name || ''}</span></h5></td>
                                                <td><span className='line-clamp line-clamp-2' title={current_obj.branch_name || ""}>{current_obj.branch_name || ''}</span></td>
                                                <td>{current_obj.account_number || ''}</td>
                                                <td><span className='line-clamp line-clamp-2' title={current_obj.beneficiary_name || ""}>{current_obj.beneficiary_name || ''}</span></td>
                                                <td>{current_obj.incentive_type && INCENTIVE_TYPE.length ?
                                                    INCENTIVE_TYPE.filter(f => f.value === current_obj.incentive_type)
                                                        .map(e => {

                                                            return (<h5 key={e.value}>{e.label || ''}</h5>)
                                                        }) : ""}
                                                </td>
                                                <td>{current_obj.incentive_period || ''}</td>

                                                <td><span onClick={() => toggleDocumentView(true, current_obj)} className="link-txt">{t("INCENTIVE_PAYMENT.VIEW")}</span></td>
                                                {props.activeTab === 'pending' ?
                                                    <td>
                                                        <div className='inbound-financier-select'>

                                                            <Select
                                                                options={((!current_obj.maker_id && !current_obj.approver_id) || (current_obj.maker_id && current_obj.entry_created === '0')) ? props.users.filter(data => data.user_roles.includes('1')) : (current_obj.maker_id && !current_obj.approver_id) ? props.users.filter(data => data.user_roles.includes('2')) : props.users}
                                                                getOptionLabel={({ diplayname }) => diplayname}
                                                                getOptionValue={({ id }) => id}
                                                                className="react-select"
                                                                classNamePrefix="react-select"
                                                                placeholder={t("INCENTIVE_PAYMENT.ASSIGNED_TO")}
                                                                value={current_obj.assigned_to ? props.users && props.users.filter(({ id }) => {
                                                                    let assigned_id = current_obj.assigned_to === "maker" ? current_obj.maker_id : current_obj.approver_id;
                                                                    return (id === assigned_id);
                                                                }) : ""}
                                                                onChange={props.updateAssignedUser.bind(this, current_obj.incentive_id, current_obj.payment_source)}
                                                                isDisabled={logged_id_user_role === 'approver' && !current_obj.approver_id ? false : true}
                                                            />

                                                        </div>

                                                    </td>
                                                    :
                                                    <>
                                                        <td>{current_obj.oto_bank && BANK_LIST.length ?
                                                            BANK_LIST.filter(f => f.value === current_obj.oto_bank)
                                                                .map(e => {

                                                                    return (<React.Fragment>
                                                                        <span>{e.label}</span>
                                                                        <span>{e.outbound_account_number || ""}</span>
                                                                    </React.Fragment>)
                                                                }) : ""}
                                                        </td>
                                                        <td>{displayDate(current_obj.payment_date)}</td>
                                                        <td>{displayDate(current_obj.updated_date)}</td>
                                                        <td><span className='line-clamp line-clamp-2' title={current_obj.utr_number || ""}>{current_obj.utr_number || ""}</span></td>
                                                        

                                                    </>}
                                                <td>{props.users && props.users.filter(data => data.id === current_obj.maker_id).map(v => v.name)[0] || ""}</td>
                                                <td>{props.users && props.users.filter(data => data.id === current_obj.approver_id).map(v => v.name)[0] || ""}</td>



                                                {
                                                    props.activeTab === 'pending' && logged_id_user_role === 'approver' && +current_obj.is_sent_to_approver === 1 && +current_obj.approver_id === +user_id ?
                                                        <td onClick={+current_obj.payment_status === 0 ? toggleUpdatePaymentView.bind(this, true, { ...current_obj, user_incentive_id: current_obj.incentive_id }):null}>
                                                            <span className={+current_obj.payment_status === 0 ? "link-txt":""}>{t("INCENTIVE_PAYMENT.UPDATE")}</span>
                                                        </td> :
                                                        props.activeTab === 'pending' ?
                                                            <td>
                                                                {+current_obj.is_sent_to_approver === 1 ? <span>{t("INCENTIVE_PAYMENT.ENTRY_CREATED")}</span> :
                                                                    (logged_id_user_role === 'maker' && +current_obj.is_sent_to_approver === 0 && +current_obj.maker_id === +user_id) ?
                                                                        <React.Fragment>
                                                                            <div className='d-flex'>
                                                                        <span className="link-txt" onClick={() => props.verifyIncentivePayment({ user_incentive_id: current_obj.incentive_id, payment_source: current_obj.payment_source })}>Create</span>
                                                                        <span className="link-txt m-sm-l" onClick={()=>syncBankInfo({ user_incentive_id: current_obj.incentive_id, sfa_user_id: current_obj.sfa_user_id })} >Sync</span> 
                                                                        </div>
                                                                        </React.Fragment>:
                                                                        <span>{t("INCENTIVE_PAYMENT.CREATE")}</span>}
                                                            </td> : null
                                                }
                                            </tr>


                                        </React.Fragment>
                                    ))
                                ) : ((!props.loading) ?
                                    <tr key="no_record">
                                        <td colSpan="12">
                                            {!false ? (
                                                <h1 className="text-danger">
                                                    {t("COMMON.NOT_FOUND")}
                                                </h1>
                                            ) : (
                                                ""
                                            )}
                                        </td>
                                    </tr> : null
                                )}


                            </tbody>
                        </table>
                    </div>
                    {props.loading || props.isFilter ? <Loader/> : null}
                    <div className="update-payment-popup no-scrollable-popup">
                        <Modal show={showUpdatePopup} handleClose={toggleUpdatePaymentView.bind(this, false, {})} >
                            <div className="modal-header">
                                <h2>{t("INCENTIVE_PAYMENT.UPDATE_PAYMENT.TITLE")}</h2>
                            </div>
                            <UpdatePayment paymentData={paymentData} toggleUpdatePayment={paymentUpdated} payment_source={props.payment_source} />
                        </Modal>
                    </div>
                    <div className={"update-payment-popup document-view-popup " + (showDocumentView ? 'document-verfication-process' : '')}>
                        <Modal show={showDocumentView} handleClose={() => toggleDocumentView(false, {})} >
                            <SimpleSlider incentiveDetails={docObj} />
                        </Modal>
                    </div>
                </div>
            </InfiniteScroll>
        </div>

    )

}
export default IncentiveTable;