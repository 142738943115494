// import PaymentService from "../service/paymentService";

export const userDetail = (data) => {
    return {
        type: 'ADD_USER_INFO',
        payload: data
    }
}

// export const leadDetail = (data) => {
//     return {
//         type: 'ADD_LEAD_INFO',
//         payload: data
//     }
// }

// export const detailPage = (data) => {
//     return {
//         type: 'DETAIL_PAGE',
//         payload: data
//     }
// }


export const updateCurrency = (data) => {
    return {
        type: 'UPDATE_CURRENCY_CONVERSION',
        payload: data
    }
}

export const logout = (data) => {
    return {
        type: 'LOGOUT',
        payload: data
    }
}



